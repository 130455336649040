import { USStateData } from '@updater/ui-widgets';

export const screens = {
  whatIsYourName: 'what-is-your-name',
  policyPapers: 'where-to-send-policy',
  addNewAddress: 'add-new-address',
  classifyResidence: 'classify-residence',
  mobileOrManufacturedHome: 'type-of-home',
  errorScreen: 'error-screen',
  howManyClaims: 'how-many-claims',
  propertyLosses: 'property-losses',
  constructionType: 'construction-type',
  mostRecentClaim: 'most-recent-claim',
  animalCausedInjury: 'animal-caused-injury',
  addAnotherPerson: 'add-another-person',
  addAnotherPersonName: 'other-person-name',
  informCoverageToManager: 'inform-third-party',
  managerNameAndAddress: 'manager-name-and-address',
};

export const customDataSchema = {
  'error-schema': {
    ui: {
      component: 'ErrorScreen',
    },
  },
  'policy-papers-wrapper': {
    ui: {
      componentProps: { controlSize: 'l' },
      wrapperComponent: 'PolicyPapersWrapper',
    },
  },
  'add-new-address-wrapper': {
    ui: {
      wrapperComponent: 'AddNewAddressWrapper',
    },
  },
  'error-validator-wrapper': {
    ui: {
      wrapperComponent: 'ErrorValidatorWrapper',
    },
  },
  'inform-coverage-manager-wrapper': {
    ui: {
      wrapperComponent: 'InformCoverageManagerWrapper',
    },
  },
  'manager-name-and-address-wrapper': {
    ui: {
      wrapperComponent: 'ManagerNameAndAddressWrapper',
    },
  },
  'has-property-losses-wrapper': {
    ui: {
      wrapperComponent: 'HasPropertyLossesWrapper',
    },
  },
};

export const layoutScreensRef = {
  whatIsYourName: {
    name: screens.whatIsYourName,
    question: ['What is your name?'],
  },
  policyPapers: {
    hideNextButton: true,
    name: screens.policyPapers,
    question: ['Is your mailing address different from your main address?'],
    title: 'Where should we send your policy papers?',
    fields: ['policy-papers-wrapper'],
  },
  classifyResidence: {
    name: screens.classifyResidence,
    question: ['How would you classify your current residence?'],
    title: 'How would you classify the residence you’re insuring?',
  },
  mobileOrManufacturedHome: {
    hideNextButton: true,
    name: screens.mobileOrManufacturedHome,
    question: ['Is it a mobile or manufactured home?'],
    title: 'Is it a mobile or manufactured home?',
    fields: ['is_mobile_or_manufactured_home', 'error-validator-wrapper'],
  },
  addNewAddress: {
    hideNextButton: true,
    name: screens.addNewAddress,
    question: ['What is your mailing address?'],
    title: 'Add a new address',
    fields: [
      'mailing_address_street',
      'mailing_address_unit',
      'mailing_address_city',
      'mailing_address_state',
      'mailing_address_postalCode',
      'add-new-address-wrapper',
    ],
  },
  howManyClaims: {
    hideNextButton: true,
    name: screens.howManyClaims,
    question: [
      'Excluding storms, floods, and other natural causes, how many losses have you had in the past three years?',
    ],
    title: 'How many claims have you had in the last 3 years?',
    subHeading: 'Excluding storms, floods, and other natural causes.',
    fields: ['number_of_losses', 'error-validator-wrapper'],
  },
  propertyLosses: {
    hideNextButton: true,
    name: screens.propertyLosses,
    question: [
      'Have you had any losses from theft/burglary, vandalism, fire or caused damage to a property in the past 3 years?',
    ],
    title:
      'Have you had any losses from theft/burglary, vandalism, fire or caused damage to a property in the past 3 years?',
    fields: ['has_losses', 'has-property-losses-wrapper'],
  },
  constructionType: {
    name: screens.constructionType,
    question: ['Select the Construction Type of Dwelling'],
    title: 'Select the Construction Type of Dwelling',
    fields: ['construction_type'],
  },
  mostRecentClaim: {
    name: screens.mostRecentClaim,
    question: ['What was the date of the most recent loss?'],
    title: 'What was the date of your most recent claim?',
    fields: ['loss_date'],
  },
  animalCausedInjury: {
    hideNextButton: true,
    name: screens.animalCausedInjury,
    question: [
      'Do you own or care for an animal that has caused bodily injury or harm? (Excluding service animals)',
    ],
    title:
      'Do you own or care for an animal that has caused bodily injury or harm?',
    subHeading: 'Excluding service animals.',
    fields: ['animal_injury', 'error-validator-wrapper'],
  },
  addAnotherPerson: {
    name: screens.addAnotherPerson,
    question: [
      'Would you like to add another person (unrelated to you) to your rental policy?',
    ],
    title: 'Would you like to add another person to your policy?',
    subHeading: 'Only applies to people unrelated to you.',
  },
  addAnotherPersonName: {
    name: screens.addAnotherPersonName,
    question: [
      'What is the name of the additional person you want named on the policy?',
    ],
    title: 'Ok, easy! Tell us their name.',
    subHeading: 'Only applies to people unrelated to you.',
  },
  informCoverageToManager: {
    hideNextButton: true,
    name: screens.informCoverageToManager,
    question: [
      'Do you want to inform an interested party about your coverage? (i.e. Rental community or property manager)?',
    ],
    title: 'Do you want to inform your property manager about your coverage?',
    subHeading:
      'Some property management companies require this before move-in.',
    fields: ['has_intrested_party', 'inform-coverage-manager-wrapper'],
  },
  managerNameAndAddress: {
    hideNextButton: true,
    name: screens.managerNameAndAddress,
    question: [
      `What is your interested party's name?`,
      `What is your interested party's address information?`,
    ],
    title: 'What is your interested party’s name and address?',
    subHeading: 'Check with your rental company if you are uncertain.',
    fields: [
      'intrested_party_name',
      'intrested_party_address_street',
      'intrested_party_address_unit',
      'intrested_party_address_city',
      'intrested_party_address_state',
      'intrested_party_address_postalCode',
      'manager-name-and-address-wrapper',
    ],
  },
};

export const customRequired = [
  'policyPapers',
  'mailing_address',
  'intrested_party_name',
  'intrested_party_address_street',
  'intrested_party_address_unit',
  'intrested_party_address_postalCode',
  'intrested_party_address_state',
  'intrested_party_address_city',
  'intrested_party_address',
];

export const customAllOf = [
  {
    if: {
      properties: {
        dwelling_type: { const: 'A' },
      },
    },
    then: {
      properties: {
        is_mobile_or_manufactured_home: {
          default: 'false',
          type: 'string',
          oneOf: [
            { const: 'false', title: 'No' },
            { const: 'true', title: 'Yes' },
          ],
          ui: {
            component: 'radiolist',
            label: 'Is it a mobile or manufactured home?',
          },
        },
      },
      required: ['is_mobile_or_manufactured_home'],
    },
  },
  {
    if: {
      properties: {
        number_of_losses: { const: '0' },
      },
    },
    then: {
      properties: {
        number_of_losses: {
          default: 'false',
          type: 'string',
          oneOf: [
            { const: '0', title: 'None' },
            { const: '1', title: 'One' },
            { const: '2', title: 'Two' },
          ],
          ui: {
            component: 'radiolist',
            label: 'Is it a mobile or manufactured home?',
          },
        },
      },
      required: ['number_of_losses'],
    },
  },
  {
    if: {
      properties: {
        loss_date: { const: '' },
      },
    },
    then: {
      properties: {
        loss_date: {
          type: 'string',
          minLength: 10,
          ui: {
            component: 'datePicker',
            componentProps: {
              minDate: null,
              maxDate: new Date(),
            },
            messages: {
              required: 'Date of your most recent claim is required',
              minLength: 'Date of your most recent claim is required',
              pattern: 'Date of your most recent claim is required',
            },
          },
        },
      },
      required: ['loss_date'],
    },
  },
];

export const interestedPartyAddressSchema = {
  intrested_party_address_street: {
    type: 'string',
    minLength: 1,
    maxLength: 30,
    ui: {
      label: 'Street',
      messages: {
        required: 'Street is required',
        minLength: 'Street is required',
        maxLength: 'Street can be at most 30 characters long',
      },
    },
  },
  intrested_party_address_unit: {
    type: 'string',
    ui: {
      label: 'Unit',
    },
  },
  intrested_party_address_city: {
    title: 'City',
    type: 'string',
    minLength: 1,
    ui: {
      label: 'City',
      messages: {
        required: 'City is required',
        minLength: 'City is required',
      },
    },
  },
  intrested_party_address_state: {
    type: 'string',
    oneOf: USStateData.map(({ name, code }) => ({
      const: code,
      title: name,
    })),
    ui: {
      label: 'State',
      width: 0.5,
    },
  },
  intrested_party_address_postalCode: {
    type: 'string',
    pattern: '^([0-9]{5})$',
    ui: {
      label: 'Zip Code',
      width: 0.5,
    },
  },
};

export const mailingAddressSchema = {
  mailing_address_street: {
    type: 'string',
    minLength: 1,
    maxLength: 30,
    ui: {
      label: 'Street',
      messages: {
        required: 'Street is required',
        minLength: 'Street is required',
        maxLength: 'Street can be at most 30 characters long',
      },
    },
  },
  mailing_address_unit: {
    type: 'string',
    ui: {
      label: 'Unit',
    },
  },
  mailing_address_city: {
    title: 'City',
    type: 'string',
    minLength: 1,
    ui: {
      label: 'City',
      messages: {
        required: 'City is required',
        minLength: 'City is required',
      },
    },
  },
  mailing_address_state: {
    type: 'string',
    oneOf: USStateData.map(({ name, code }) => ({
      const: code,
      title: name,
    })),
    ui: {
      label: 'State',
      width: 0.5,
    },
  },
  mailing_address_postalCode: {
    type: 'string',
    pattern: '^([0-9]{5})$',
    ui: {
      label: 'Zip Code',
      width: 0.5,
    },
  },
};

export const newScreens = [
  {
    keyName: screens.errorScreen,
    layout: {
      hideNextButton: true,
      name: screens.errorScreen,
      heading: 'Unfortunately, we are not able to secure a policy for you.',
      title: 'Unfortunately, we are not able to secure a policy for you.',
      subHeading:
        'Thankfully, we can still help you complete other moving tasks.',
      route: screens.errorScreen,
      fields: ['error-schema'],
      nextButtonText: 'Back to home',
    },
    schema: {
      type: 'string',
      minLength: 1,
      ui: {
        label: screens.errorScreen,
      },
    },
  },
];
